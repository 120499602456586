<template>
    <section class="content">
        <div class="card">
            <div class="card-header">
                Filter Pencarian
            </div>
                <!-- <div class="p-5"></div> -->
            <div class="card-body row">
                <div class="col-md-4 col-xs-12 form-group">
                    <div class="row">
                        <div class="col d-flex justify-content-between">
                            <label class="control-label">ASAL PENGIRIMAN</label>
                            <label class="control-label text-primary cursor-pointer" v-on:click="selectWarehouse">Pilih Alamat <i class="fi fi-rr-angle-right"></i></label>
                        </div>
                    </div>
                    <v-select required label="name" name="sender_addr_id" :filterable="false" :options="addrOptions1" v-model="form.sender_addr_id" :reduce="(opt) => opt.id" @search="onSearch1">
                    <template slot="no-options"> Ketik asal pengiriman... </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.lokasi }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.lokasi }}
                        </div>
                    </template>
                    </v-select>
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                    <label class="control-label">TUJUAN PENGIRIMAN</label>
                    <v-select required label="name" name="addr_id" :filterable="false" :options="addrOptions2" v-model="form.addr_id" :reduce="(opt) => opt.id" @search="onSearch2">
                    <template slot="no-options"> Ketik tujuan pengiriman... </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.lokasi }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.lokasi }}
                        </div>
                    </template>
                    </v-select>
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                    <label class="control-label">BERAT (Kg)</label>
                    <input class="form-control" v-model="form.weight" type="number" value="1" min="0.5" max="99" step="0.1" required="required" placeholder="Bisa desimal" name="weight" 
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                </div>
                 <div class="col-md-4 col-xs-12 form-group">
                    <label class="control-label">Panjang (Cm)</label>
                    <input class="form-control" v-model="form.l" type="number" value="1" min="0.5" step="0.1" required="required" placeholder="Bisa desimal" name="l" 
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                    <label class="control-label">Lebar (Cm)</label>
                    <input class="form-control" v-model="form.w" type="number" value="1" min="0.5" step="0.1" required="required" placeholder="Bisa desimal" name="w" 
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                    <label class="control-label">Tinggi (Cm)</label>
                    <input class="form-control" v-model="form.h" type="number" value="1" min="0.5" step="0.1" required="required" placeholder="Bisa desimal" name="h" 
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" :disabled="checking" v-on:click="search" class="btn btn-primary btn-md float-right"><i class="fas fa-search"></i>&nbsp;Cek Sekarang <span v-if="checking" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
            </div>
        </div>

        <div class="card">
            <div v-if="resultOngkir.length > 0" class="card-body">
                <div v-if="resultOngkir.length > 0" class="row d-flex justify-content-between align-items-center" style="background-color: #F5F5F5; padding: 20px;">
                    <div class="col">
                        <div class="form-control py-3 px-3 h-100" v-bind:class="(service_category == 0 ? 'border-primary  bg-primary' : ' border')" style="border-width: 2px;">
                            <input type="radio" id="regular" name="service_category" v-model="service_category" value="0" hidden  @change="onToggleChange($event)"/>
                            <label for="regular" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="gap: .5rem; justify-content: center;" v-bind:class="{ 'text-secondary' : service_category != 0 }">Reguler</label>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-control py-3 px-3 h-100" v-bind:class="(service_category == 2 ? 'border-primary  bg-primary' : ' border')" style="border-width: 2px;">
                            <input type="radio" id="sameday" name="service_category" v-model="service_category" value="2" hidden  @change="onToggleChange($event)"/>
                            <label for="sameday" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="gap: .5rem; justify-content: center;" v-bind:class="{ 'text-secondary' : service_category != 2 }">Sameday</label>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-control py-3 px-3 h-100" v-bind:class="(service_category == 3 ? 'border-primary  bg-primary' : ' border')" style="border-width: 2px;">
                            <input type="radio" id="nextday" name="service_category" v-model="service_category" value="3" hidden  @change="onToggleChange($event)"/>
                            <label for="nextday" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="gap: .5rem; justify-content: center;" v-bind:class="{ 'text-secondary' : service_category != 3 }">Nextday</label>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-control py-3 px-3 h-100" v-bind:class="(service_category == 1 ? 'border-primary  bg-primary' : ' border')" style="border-width: 2px;">
                            <input type="radio" id="cargo" name="service_category" v-model="service_category" value="1" hidden  @change="onToggleChange($event)"/>
                            <label for="cargo" class="form-check-label cursor-pointer w-100 d-flex flex-row" style="gap: .5rem; justify-content: center;" v-bind:class="{ 'text-secondary' : service_category != 1 }">Cargo</label>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <div class="input-group border mb-3" v-for='item in listCourierSelect'>
                        <div style="min-height: 50px; flex: 1 1 auto;width: 1%;" class="w-auto p-3">
                            <div class="row">
                                <div class="col-md col-sm-12">
                                    <img :src="getKurirLogo(item.kurir)" style="height:40px;" />
                                </div>
                                <div v-if="item.rating > 0" class="col-md col-sm-12">
                                    <div  class="description text-left mt-0">
                                        <span class="description-text" style="color: gray;">Rating kurir</span>
                                        <div class="row" style="align-items: center;">
                                            <star-rating :inline="true" :star-size="15" :read-only="true" :show-rating="false" :rating="item.rating" style="margin-bottom: 6px;" v-bind:increment="0.01"></star-rating>
                                            <h5 class="description-header" style="margin-top: 5px; margin-left: 5px;">{{ item.rating }}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md col-sm-12">
                                    <div class="description text-left mt-0">
                                        <span class="description-text" style="color: gray;">Service kurir</span>
                                        <h5 class="description-header" style="margin-top: 5px;">{{ item.serviceName }}</h5>
                                    </div>
                                </div>
                                <div class="col-md col-sm-12">
                                    <div class="description text-left mt-0">
                                        <span class="description-text" style="color: gray;">Estimasi</span>
                                        <h5 class="description-header" style="margin-top: 5px;">{{ item.etd_from }} - {{ item.etd_thru }} hari</h5>
                                    </div>
                                </div>
                                <div class="col-md col-sm-12">
                                    <div class="description text-left mt-0">
                                        <span class="description-text" style="color: gray;">Tipe Kiriman</span>
                                        <div>
                                            <span v-if="item.cod == '1'" class="badge badge-warning" style="margin-right: 2px;">COD</span>
                                            <span class="badge badge-warning">Non COD</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md col-sm-12">
                                    <div class="description text-left mt-0">
                                        <span class="description-text" style="color: gray;">Tarif Ongkir Normal</span>
                                        <h5 class="description-header" style="margin-top: 5px;">{{ formatPrice(item.maxPrice) }}</h5>
                                    </div>
                                </div>
                                <div class="col-md col-sm-12">
                                    <div class="description text-left mt-0">
                                        <span class="description-text" style="color: gray;">Tarif Ongkir bosCOD</span>
                                        <h5 class="description-header" style="margin-top: 5px;">{{ item.ppn_ongkir_inc == "0" ? formatPrice((item.price - item.disc) + item.ppn_ongkir) :  formatPrice(item.price - item.disc)}} &nbsp; <i class="fas fa-info-circle" :title="'Diskon '+item.percent_cashback+'%'"></i></h5>
                                    </div>
                                </div>
                                <div v-if="item.weight != form.weight && item.weight > 1" class="col-md col-sm-12">
                                    <div class="description text-left mt-0">
                                        <span class="description-text" style="color: gray;">Berat Volumetrik</span>
                                        <h5 :title="'Pengukuran berat dihitung berdasarkan volume'" class="description-header" style="margin-top: 5px;">{{ item.weight}} Kg &nbsp;</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div
            v-for="row in resultOngkir"
            v-bind:key="row.service"
            v-bind:value="row.service"
            class="col-md-12">
        
        </div> -->
        <WarehouseCekOngkir :show="show" @input="onChangeWarehouse" v-model="sender" />
    </section>
</template>
<script src="https://unpkg.com/vue-star-rating@next/dist/VueStarRating.umd.min.js"></script>
<script>
import { createTable, authFetch, formatCurrency } from '@/libs/hxcore';
import $ from 'jquery';
// import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import WarehouseCekOngkir from "@/dialog/WarehouseCekOngkir";
import moment from "moment";
import StarRating from 'vue-star-rating';

export default {
    name: 'CekOngkir',
    components: {
        vSelect,
        WarehouseCekOngkir,
        StarRating,
        // datepicker,
    },
    data() {
        return {
            service_category: 0,
            errors: [],
            addrOptions1: [],
            addrOptions2: [],
            senderOptions: [],
            resultOngkir: [],
            roles: '',
            checking: false,
            show: false,
            form: {
                addr_id: '',
                sender_addr_id: '',
                weight: 1,
                w: 10,
                l: 10,
                h: 10,
            },
            // tgl: '',
            // kelas: '',
            signals: null,
            totalCourier: [],
            listCourier: [],
            listCourierSelect: [],
            ongkirInProgres: [],
            sender: {},
        }
    },
    created: function() {
        // this.roles = this.$route.meta.roles;
        // console.log('load initial data', this.$route)
        //this.loadAddress();
        this.loadSenderAddress();
    },
    methods: {
        formatPrice(data){
            return formatCurrency(data);
        },
        getKurirLogo(kurir) {

            const fileName = kurir.toLowerCase();

            return require(`../assets/img/${fileName}.png`) // the module request
        },
        search: function() {
            let self = this;
            self.form.addr_id = self.form.addr_id;
            self.form.sender_addr_id = self.form.sender_addr_id;
            self.form.weight = self.form.weight;
            if (!self.form.sender_addr_id) {
                self.resultOngkir = [];
                return Swal.fire("Asal gudang belum dipilih!", ``, "error");
            }
            if (!self.form.addr_id) {
                self.resultOngkir = [];
                return Swal.fire("Tujuan pengiriman belum dipilih!", ``, "error");
            }
            if (self.form.weight > 99) {
                self.resultOngkir = [];
                return Swal.fire("Berat maksimum 99 Kg!", ``, "error");
            }
            //this.loadOngkir(self.form);
            this.stopLoadOngkir();
            this.mapOngkir();
        },
        stopLoadOngkir: function() {
			if(this.signals!=null){
				this.signals.abort();
				this.ongkirInProgres = [];
			}
		},
        loadAddress() {
            let route;
                route = "/order/cekongkir/address";
            authFetch(route).then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        // this.sender = json.data;
                        this.addrOptions1.push({
                            id: json.data.addr_id,
                            lokasi: json.data.kota + " / " + json.data.kecamatan,
                        });
                        this.addrOptions2.push({
                            id: json.data.addr_id,
                            lokasi: json.data.kota + " / " + json.data.kecamatan,
                        });
                    }
                });
            });
        },
        loadSenderAddress() {
            authFetch('/order/cekongkir/asalgudang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.senderOptions = js.data;
                $('.loading-overlay').removeClass('show');
            });
        },
        onSearch1(search, loading) {
            if (search.length > 3) {
                loading(true);
                //this.search(loading, search, this);
                var vm = this;
                authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
                    res.json().then((json) => (vm.addrOptions1 = json.items));
                    loading(false);
                });
            }
        },
        onSearch2(search, loading) {
            if (search.length > 3) {
                loading(true);
                //this.search(loading, search, this);
                var vm = this;
                authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
                    res.json().then((json) => (vm.addrOptions2 = json.items));
                    loading(false);
                });
            }
        },
        loadOngkir(params) {
            const self = this;
            let data;
            let route;
            data = Object.keys(params)
            .map(
                (key) =>
                encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
            )
            .join("&");
            route = "/order/cekongkir/cek?" + data;
            // var weight = self.formItem.weight;
            // if (!weight) weight = 1;
            // if (s_id) {
            //     route = `/order/nilai_ongkir/${courier_id}/${s_id}/${r_id}/${weight}`;
            // } else {
            //     route = "/order/nilai_ongkir/854/887";
            // }
            // if (self.form.shipper_id) {
            //     route = route + '?shipper_id=' + self.form.shipper_id;
            // }
            this.checking = true;
            authFetch(route).then((res) => {
                res.json().then((json) => {
                    console.log("data ", json);
                    self.resultOngkir = json;
                    this.checking = false;
                });
            });
        },
        async loadCourier() {
			// this.resetService();
			let route = "/order/kurir";
			try {
				await authFetch(route).then(res => {
					res.json().then(json => {
						if (json.success == true) {
							this.listCourier = json.data;
							this.mapOngkir();
						} else {
							Swal.fire({
								title: "Oops...",
								icon: "error",
								text: json.message,
								showCloseButton: true
							});
						}

					});
				});
			} catch (error) {
				
			}
		},
        mapOngkir() {
            const self = this;
            this.ongkirInProgres =[];
			let data;
			this.ongkirInProgres =[];
			this.resultOngkir = [];
			if (this.signals != null) {
				this.signals.abort();
			}
			const controller = new AbortController();
			this.signals = controller;
			data = this.listCourier.length > 0 ? this.listCourier.map((val,key) => {
				var data = {};
				data.addr_id = self.form.addr_id;
				data.sender_addr_id = self.form.sender_addr_id;
				data.weight = self.form.weight;
				data.courier = val.id;
				data.order_type = this.form.order_type;
				data.w = self.form.w;
				data.h = self.form.h;
				data.l = self.form.l;
				this.newLoadOngkir(data);
			}) : this.loadCourier();
		},
		newLoadOngkir(params) {
			const self = this;
			params.codStatus=0;
			const courierRecommendation = 1; //idx
			//param.courier=this.listCourier;
			let data;
			let route;
			data = Object.keys(params)
				.map(
					key =>
						encodeURIComponent(key) +
						"=" +
						(params[key] === false || params[key] === undefined
							? 0
							: encodeURIComponent(params[key]))
				)
				.join("&");
			route = "/order/ongkir?" + data;
			this.checking = true;
			this.loadingOngkir = true;

			authFetch(route,{signal : this.signals.signal}).then(res => {
				res.json().then(json => {
					if(params.courier ==  courierRecommendation){
						console.log(params.courier);
						const insertAt = 0;
						self.resultOngkir = [...json].concat(self.resultOngkir);
						// self.resultOngkir.push(...json);
					}else{
						self.resultOngkir.push(...json);
					}
					this.checking = false;
					this.loadingOngkir = false;
					if (Object.keys(this.resultOngkir).length == 1 && Object.keys(this.listCourier).length == 1) {
						this.selectService(0);// if only one option
					} else {
						this.showServiceList = true;
					}
                    this.onToggleChange();
				}).
				finish(
					this.ongkirInProgres.push(...params.courier)
				);
			});
		},
        selectWarehouse: function() {
			this.show = !this.show;
		},
        onChangeWarehouse: function(e)
		{
            this.addrOptions1 = [e];
            this.form = {
                addr_id: e.addr_id,
                sender_addr_id: e.addr_id,
                weight: this.form.weight,
                w: this.form.w,
                l: this.form.l,
                h: this.form.h,
            },
			this.sender.error = false;
		},
        onToggleChange: function()
		{
            var listCourierSelect = [];
            for (let i = 0; i < this.resultOngkir.length; i++) {
                if (this.resultOngkir[i].category == this.service_category){
                    listCourierSelect.push(this.resultOngkir[i]);
                }
            }
            this.listCourierSelect = listCourierSelect;
		},
    },
    mounted() {
        console.log('load initial data', this.$route.params.roles);
        const e = this.$refs;
        var self = this;
    },
    watch: {
		ongkirInProgres: function(val) {
			//do something when the data changes.
			Object.keys(this.ongkirInProgres).length==Object.keys(this.listCourier).length==1
			if (Object.keys(this.ongkirInProgres).length==Object.keys(this.listCourier).length==1) {
				this.signals=null;
			}
		},
	},
}
</script>